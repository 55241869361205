import Rest from '@/services/Rest';

/**
 * @typedef {TechnicalService}
 */
export default class TechnicalService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/technical'
}
