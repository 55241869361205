<template>
  <div style="margin-top: 80px;">
    <BasePageBreadCrumb :pages="pages" :title="id ? 'editar chamado' : 'novo chamado'" :menu="menu"/>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <div class="form-body">
                                    <div class="row">
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Abrir chamado como: </label>
                                                <select class="custom-select mr-sm-2" v-model="report.open_like" v-validate="'required'" name="open_like" :class="{'is-invalid': errors.has('open_like')}">
                                                    <option :value="null">Selecione</option>
                                                    <option value="LIQUIDATOR">Síndico</option>
                                                    <option value="TECHNICAL">Técnico</option>
                                                    <option value="LESSE">Locatário</option>
                                                    <option value="CLIENT">Cliente/Proprietário</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-8 col-lg-8">
                                            <div class="form-group">
                                                <label>Assunto </label>
                                                <input type="text" class="form-control" placeholder="Identificação do chamado" v-model="report.subject" v-validate="'required'" name="subject" :class="{'is-invalid': errors.has('subject')}">
                                            </div>
                                        </div>
                                        <div class="col-12 pt-3"></div>
                                        <div class="col-12"  v-if="report.open_like != 'LIQUIDATOR'">
                                            <BaseClientBox :clients="clients" v-model="report.client_id" />
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12">
                                            <div class="form-group">
                                                <BaseEnterpriseBox v-model="report.enterprise_id" />
                                            </div>
                                        </div>
                                        <div class="col-12 pb-2" v-if="report.open_like != 'LIQUIDATOR'">
                                            <BaseAptClientOnlyBox v-model="report.floors" :enterprise_id="report.enterprise_id" :edit="false" :disabled="false" :client="true"/>
                                        </div>
                                        <div class="col-12 pb-2" v-if="report.open_like == 'LIQUIDATOR'">
                                            <BaseCondBox v-model="report.conds" />
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>N˚ torre</label>
                                                <b-form-select v-model="report.tower" name="tower" :class="{'is-invalid': errors.has('tower')}" :options="towers"></b-form-select>
     <!-- v-validate="'required'" -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Qual status do chamado? </label>
                                                <select class="custom-select mr-sm-2" v-model="report.status" v-validate="'required'" name="status" :class="{'is-invalid': errors.has('status')}">
                                                    <option :value="null">Selecione</option>
                                                    <option value="PENDING">Novo</option>
                                                    <option value="IN_PROGRESS">Em andamento</option>
                                                    <option value="FINISHED">Resolvido</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Não conformidades</label>
                                                <b-form-select v-model="report.conformity_id" :options="conformities" v-validate="'required'" name="not_conformity" :class="{'is-invalid': errors.has('not_conformity')}"></b-form-select>

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Procedentes</label>
                                                <b-form-select v-model="report.precedent_id" :options="precedents" v-validate="'required'" name="precedent" :class="{'is-invalid': errors.has('precedent')}"></b-form-select>

                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Data/Hora agendamento </label>
                                                <input type="datetime-local" class="form-control" v-model="report.date" name="date"  :class="{'is-invalid': errors.has('date')}">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Técnico reponsável</label>
                                                  <select v-model="report.technical_id"  :class="{'is-invalid': errors.has('technical')}" class="form-control selectsearch technical"  id="technical" style="height: 48px !important">
                                                    <option v-for="item in technical" :value="item.value">{{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label>Síndico reponsável</label>
                                               <select  v-model="report.liquidator_id" class="form-control selectsearch  liquidator" :class="{'is-invalid': errors.has('liquidator')}" id="liquidator" style="height: 48px !important">
                                                    <option v-for="item in liquidator" :value="item.value">{{item.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12 pt-4">
                                            <div class="form-group">
                                                <label>Observação </label>
                                                <textarea class="form-control" rows="6" placeholder="Mensagem" v-model="report.description" v-validate="'required'" name="observation" :class="{'is-invalid': errors.has('observation')}"></textarea>
                                                <small id="textHelp" class="form-text text-muted">Informe detalhes sobre o chamado.</small>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-12 col-lg-12">
                                            <vue-upload-multiple-image
                                                @upload-success="uploadImageSuccess"
                                                @before-remove="beforeRemove"
                                                @edit-image="editImage"
                                                :data-images="report.images"
                                                idUpload="myIdUpload"
                                                editUpload="myIdEdit"
                                                dragText="Clique ou arraste uma image aqui"
                                                browseText="Procurar imagem"
                                                :showPrimary="false"
                                                :showEdit="false">
                                            </vue-upload-multiple-image>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="reset" class="btn btn-dark">Cancelar</button>
                                        <button type="submit" class="btn btn-info ml-2">{{ id ? 'Atualizar' : 'Cadastrar'}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import ReportService from "@/services/resources/ReportService";
import ClientService from "@/services/resources/ClientService";
import UserService from "@/services/resources/UserService";
import EnterpriseService from "@/services/resources/EnterpriseService";
import EnterpriseConfigService from "@/services/resources/EnterpriseConfigService";
import ConfigService from "@/services/resources/ConfigService";
import TechnicalService from "@/services/resources/TechnicalService";
import LiquidatorService from "@/services/resources/LiquidatorService";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import moment from 'moment';

const serviceReport = ReportService.build();
const serviceClient = ClientService.build();
const serviceUser = UserService.build();
const serviceEnterprise = EnterpriseService.build();
const serviceEnterpriseConfig = EnterpriseConfigService.build();
const serviceConfig = ConfigService.build();
const serviceTechnical = TechnicalService.build();
const serviceLiquidator = LiquidatorService.build();

export default {
  components:{
    VueUploadMultipleImage
  },
  data() {
    return {
      report:{
        open_like: null,
        client_id: null,
        conds: null,
        enterprise_id: null,
        liquidator_id: null,
        subject: null,
        tower: null,
        status: null,
        conformity_id: null,
        precedent_id: null,
        technical_id: null,
        description: null,
        date: null,
        floors: [],
        images: []
      },
      clients: [
        { value: null, text: 'Selecione' }
      ],
      enterprises: [
        { value: null, text: 'Selecione' }
      ],
      conformities: [
        { value: null, text: 'Selecione' }
      ],
      precedents: [
        { value: null, text: 'Selecione' }
      ],
      technical: [
        { value: null, text: 'Selecione' }
      ],
      liquidator: [
        { value: null, text: 'Selecione' }
      ],
      cond: [
        { value: null, text: 'Selecione' }  
      ],
      towers: [
        { value: null, text: 'Selecione' }  
      ],
      floors: [],
      id: null,
      pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            },
            {
                name: 'assistẽncia técnica',
                to: 'Dashboard'
            },
            {
                name: 'novo chamado',
                to: 'ReportNew'
            }
      ],
      menu:[
            {
                name: 'Novo chamado',
                to: 'ReportNew'
            },
            {
                name: 'Chamados',
                to: 'Report'
            },
            {
                name: 'Novo técnico',
                to: 'TechnicalNew'
            },
            {
                name: 'Técnicos',
                to: 'Report'
            },
            {
                name: 'Novo síndico',
                to: 'LiquidatorNew'
            },
            {
                name: 'Síndicos',
                to: 'Liquidator'
            }
        ]
    };
  },
  watch:{
      "report.client_id"(){
        if(this.report.client_id){
          this.fetchClient();
        }
      },
      "report.enterprise_id"(){
        if(this.report.enterprise_id){
          this.selectEnterprise(this.report.enterprise_id);
        }
      }
  },
  methods: {
    uploadImageSuccess(formData, index, fileList) {
        this.report.images = fileList;
    },
    beforeRemove(index, done, fileList) {
        var r = confirm("Remover imagem?");
        if (r == true) {
            this.report.images.splice(index, 1);
            done();
        }
    },
    editImage(formData, index, fileList) {
        this.report.images = fileList;
    },
    generateGrounds(grounds){
        let total = 0;
        for(let i = 0; i < grounds.length; i++){
            total += grounds[i].apt_numbers * grounds[i].floors;
        }

        return total;
    },
    async fetchConfig(type){
        let data = {
          type: type
        }
        return serviceConfig.search(data).then();
    },
    async fetchClients(){
        return serviceClient.search().then();
    },
    async fetchEnterprise(){
        return serviceEnterprise.search().then();
    },
    async fetchEnterpriseConfig(type){
        let data = {
          type: type
        }
        return serviceEnterpriseConfig.search(data).then();
    },
    async fetchTechnical(){
        return serviceTechnical.search().then();
    },
    async fetchLiquidator(){
        return serviceLiquidator.search().then();
    },
    async selectEnterprise(id){
        let enterprise = await serviceEnterprise.read({id: id}).then();
        this.towers = [
            { value: null, text: 'Selecione' }  
        ];
        this.towers = this.towers.concat(new Array(enterprise.tower).fill().map((e,i) => {
            return {value: i+1, text: i+1}
        }));

    },
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {

            
          let report = Object.assign({}, this.report);
          report.date = moment(report.date).isValid() ? moment(report.date).format("YYYY-MM-DD H:mm") : null;
          if (this.report.open_like == "LIQUIDATOR") {
            report.conds = Array.isArray(report.conds) && report.conds.length > 0 ? report.conds : Array.isArray(report.conds) && report.conds.length == 0 ? null : [report.conds];
          }

            if(this.id){
                report.id = this.id;
                serviceReport
                .update(report)
                .then(resp => {
                    this.$bvToast.toast('Seu chamado foi atualizado com sucesso!', {
                        title: 'Chamado atualizado',
                        autoHideDelay: 5000,
                        type: 'success'
                    });
                    
                })
                .catch(err => {
                    this.$bvToast.toast('Ocorreu um erro inesperado, por favor, tente mais tarde.', {
                        title: 'Atualizar chamado',
                        autoHideDelay: 5000,
                        type: 'danger'
                    });
                })
                
            }else{
                 serviceReport
                .create(report)
                .then(resp => {
                    this.$bvToast.toast('Seu chamado foi criado com sucesso!', {
                        title: 'Chamado criado',
                        autoHideDelay: 5000,
                        type: 'success'
                    });
                    this.report = {
                        open_like: null,
                        client_id: null,
                        conds: null,
                        enterprise_id: null,
                        subject: null,
                        tower: null,
                        status: null,
                        conformity_id: null,
                        precedent_id: null,
                        technical_id: null,
                        description: null,
                        date: null,
                        floors: [],
                        images: []
                    };
                    this.$validator.reset();
                    
                })
                .catch(err => {
                     this.$bvToast.toast('Ocorreu um erro inesperado, por favor, tente mais tarde.', {
                        title: 'Criar chamado',
                        autoHideDelay: 5000,
                        type: 'danger'
                    });
                })
            }
         
          }
      });
    },
    fetchClient(){

        let data = {
            id: this.report.client_id
        };

        serviceUser
        .read(data)
        .then(resp => {
            if(resp.enterprises.length > 0){
                this.report.enterprise_id = resp.enterprises[0].enterprise_id;
            }
            if(resp.unities.length > 0){
                this.report.floors = resp.unities.map(elem => elem.unity_id);
            }
        }).catch(err => {
            console.log(err);
        });
    },
    fetchReport(){

        if(!this.id){
            return;
        }

        let data = {
            id: this.id
        };

        serviceReport
        .read(data)
        .then(resp => {
            this.report = resp;
            if(resp.conds.length > 0){
                this.report.conds = resp.conds.map(elem => {
                    return elem.config_id
                });
            }
            this.report.conds = this.report.conds.length > 1 ? this.report.conds : this.report.conds.length > 0 ? this.report.conds[0] : [];
            this.report.date = moment(this.report.date).format('YYYY-MM-DD[T]HH:mm');
            this.selectEnterprise(this.report.enterprise_id);
            this.report.floors = resp.floors.map(elem => {
                return elem.apt_number
            });

        })
        .catch(err => {
            console.log(err)
        })
    }

  },
  async mounted() {

    this.id = this.$route.params.id;
    this.fetchReport();

    var resp = await this.fetchClients();
    this.clients = this.clients.concat(resp[0].map(elem => {
        return {
            value: elem.id,
            text: elem.first_name + ' ' + elem.last_name
        }
    }))

    var resp = await this.fetchConfig('NOT_CONFORMITY');
    this.conformities = this.conformities.concat(resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    }))

    var resp = await this.fetchConfig('PRECEDENTS');
    this.precedents = this.precedents.concat(resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    }))

    var resp = await this.fetchEnterpriseConfig('COND');
    this.cond = this.cond.concat(resp.map(elem => {
        return {
            value: elem.id,
            text: elem.name
        }
    }))

    var resp = await this.fetchTechnical();
    this.technical = this.technical.concat(resp.map(elem => {
        return {
            value: elem.id,
            text: elem.first_name + ' ' + elem.last_name
        }
    }))

    var resp = await this.fetchLiquidator();
    this.liquidator = this.liquidator.concat(resp.map(elem => {
        return {
            value: elem.id,
            text: elem.first_name + ' ' + elem.last_name
        }
    }))

    console.log(this.liquidator);

        $(document).ready(() => {
            $('#technical').select2({
                "language":{
                    "noResults" : function () { return 'Busca não encontrada'; }
                }                                    
            }); 
             $(".technical").val(this.$data.report.technical_id)
        });

        
        $(document).ready(() => {
            $('#liquidator').select2({
                "language":{
                    "noResults" : function () { return 'Busca não encontrada'; }
                }                                    
            }); 
            $(".liquidator").val(this.$data.report.liquidator_id)
        });



           

        


   

  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>